import { gtmKey, gtagId, fbPixelId, adsID } from '@constants/configuration'
import { isParamSet } from '@constants/utils'

const gtmEnabled = isParamSet(gtmKey)

export const sendGtmEvent = (data: Record<string, unknown>): void => {
  if (gtmEnabled) {
    if (appDevMode) {
      // eslint-disable-next-line no-console
      console.debug('GA event', data)
    }
    // eslint-disable-next-line functional/immutable-data
    dataLayer?.push(data)
  }
}

// Google Analytics (GTAG)

const gtagEnabled = isParamSet(gtagId)

export const sendGtagEvent = (eventName: string, params: Record<string, unknown> = {}): void => {
  if (gtagEnabled && typeof gtag === 'function') {
    if (appDevMode) {
      // eslint-disable-next-line no-console
      console.debug('GTAG event', eventName, params)
    }
    // eslint-disable-next-line functional/immutable-data
    gtag('event', eventName, params)
  }
}

// Facebook Pixel

const fbPixelEnabled = isParamSet(fbPixelId)

export const sendFacebookEvent = (eventName: string, params: Record<string, unknown> = {}): void => {
  if (fbPixelEnabled && typeof fbq === 'function') {
    if (appDevMode) {
      // eslint-disable-next-line no-console
      console.debug('Facebook Pixel event', eventName, params)
    }
    // eslint-disable-next-line functional/immutable-data
    fbq('trackCustom', eventName, params)
  }
}

export const sendTrackingEvent = (
  platforms: Array<'gtm' | 'gtag' | 'fb'>,
  eventName: string,
  params: Record<string, unknown> = {}
): void => {
  if (platforms.includes('gtm')) {
    sendGtmEvent({ event: eventName, ...params })
  }
  if (platforms.includes('gtag')) {
    sendGtagEvent(eventName, params)
  }
  if (platforms.includes('fb') && typeof fbq === 'function') {
    const fbEventName = getFacebookEventName(eventName)
    fbq('trackCustom', fbEventName, params)
  }

  if (appDevMode) {
    // eslint-disable-next-line no-console
    console.debug(`Event sent to: ${platforms.join(', ')}`, eventName, params)
  }
}

// Helper function to map event names to Facebook's standard events
const getFacebookEventName = (eventName: string): string => {
  const eventMapping: Record<string, string> = {
    generate_lead: 'LEAD',
    purchase: 'PURCHASE',
    add_payment_info: 'ADD_PAYMENT_INFO',
    add_to_cart: 'ADD_TO_CART',
    add_to_wishlist: 'ADD_TO_WISHLIST',
    complete_registration: 'COMPLETE_REGISTRATION',
    contact: 'CONTACT',
    customize_product: 'CUSTOMIZE_PRODUCT',
    donate: 'DONATE',
    find_location: 'FIND_LOCATION',
    initiate_checkout: 'INITIATE_CHECKOUT',
    schedule: 'SCHEDULE',
    search: 'SEARCH',
    start_trial: 'START_TRIAL',
    submit_application: 'SUBMIT_APPLICATION',
    subscribe: 'SUBSCRIBE',
    view_content: 'VIEW_CONTENT',
  }

  return eventMapping[eventName] || eventName
}

export const sendSimpleFacebookEvent = (eventName: string): void => {
  if (fbPixelEnabled && typeof fbq === 'function') {
    if (appDevMode) {
      // eslint-disable-next-line no-console
      console.debug('Facebook Pixel event', eventName)
    }
    // eslint-disable-next-line functional/immutable-data
    fbq('track', eventName)
  }
}
const googleAdsEnabled = isParamSet(adsID)

export const sendGoogleAdsConversion = (conversionId: string): boolean => {
  if (!googleAdsEnabled) {
    if (appDevMode) {
      // eslint-disable-next-line no-console
      console.warn('Google Ads tracking is disabled')
    }
    return false
  }

  if (typeof gtag === 'function') {
    gtag('event', 'conversion', {
      send_to: `${adsID}/${conversionId}`, // Using adsID from configuration directly
    })

    if (appDevMode) {
      // eslint-disable-next-line no-console
      console.debug(`Google Ads conversion event sent to: ${adsID}/${conversionId}`)
    }

    return true
  } else {
    if (appDevMode) {
      // eslint-disable-next-line no-console
      console.error('gtag function is not available')
    }
    return false
  }
}
